import { HeaderProps, Header } from "@Light/components/page/header/Header";
import { CloseIcon } from "./CloseIcon";
import React from "react";
export const FlowHeader: React.FC<HeaderProps> = React.forwardRef(
  (props, ref) => {
    return (
      <Header
        ref={ref}
        {...props}
        titleLink={undefined}
        rightButton={<CloseIcon />}
      />
    );
  },
);
