import { useLogout } from "@/utils/logout";
import { EmptyHeader } from "./EmptyHeader";
import { useScaffold } from "@Light/scaffold";
import { useCallback } from "react";
import { WithPopover } from "@Light/components/page/popover/WithPopover";
import { SupportPopover } from "@Light/components/enroll/SupportButton";
import clsx from "clsx";

export type SessionExpiredProps = {
  title?: string;
};

export const SessionExpired: React.FC<SessionExpiredProps> = ({ title }) => {
  const logout = useLogout("session_expired");
  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;
  return (
    <WithPopover popover={<SupportPopover hideFAQ />} className="h-full">
      {useCallback(
        ({ open }) => (
          <div className="flex flex-col h-full">
            <EmptyHeader />
            <div className="flex justify-center items-center text-center h-full px-4">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <Title>{title || "Your session has expired"}</Title>
                  <Subtitle>
                    <span>
                      Something unexpected occurred. Please close and try again.
                      If the error persists, please
                    </span>{" "}
                    <span
                      className={clsx(
                        "font-medium cursor-pointer",
                        scaffold.page.colors.text.info,
                      )}
                      onClick={open}
                    >
                      contact us
                    </span>
                    <span>.</span>
                  </Subtitle>
                </div>
                <button
                  className="bg-slate-900 text-white rounded rounded-md p-2"
                  onClick={logout}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ),
        [],
      )}
    </WithPopover>
  );
};
