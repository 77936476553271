import { useLogout } from "@/utils/logout";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export type CloseProps = {};

export const Close: React.FC<CloseProps> = ({}) => {
  // get the current react router path
  const location = useLocation();

  const logout = useLogout(`route:${location.pathname}`);

  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};
