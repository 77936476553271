import { HeaderProps } from "@Light/components/page/header/Header";
import clsx from "clsx";

import React from "react";
import { CloseIcon } from "./CloseIcon";
export const EmptyHeaderScaffold: React.FC<HeaderProps> = React.forwardRef(
  ({ onBack, leftButton, rightButton, className, ...props }, ref) => {
    return <EmptyHeader ref={ref} {...props} />;
  },
);

export const EmptyHeader: React.FC<React.ComponentProps<"header">> =
  React.forwardRef(({ className, ...props }, ref) => {
    return (
      <header
        ref={ref}
        className={clsx("pt-4 pb-4 px-1 w-full", className)}
        {...props}
      >
        <div className="flex flex-row justify-between items-center">
          <div />
          <div className="p-2">
            <CloseIcon />
          </div>
        </div>
      </header>
    );
  });
