import { useAPI } from "@/services/api";
import { useCallback, useEffect } from "react";
import { closeParent } from "./messages";
import { captureEvent } from "@Light/app/analytics";

export const useLogout = (source?: string) => {
  const { useLogoutMutation } = useAPI();
  const [logout, logoutState] = useLogoutMutation();
  const onClick = useCallback(() => {
    captureEvent("flow_logout", { logout_source: source ?? "other" });
    logout();
  }, [logout, source]);
  useEffect(() => {
    if (logoutState.isSuccess || logoutState.isError) {
      closeParent();
    }
  }, [logoutState]);

  return onClick;
};
