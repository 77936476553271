import { useLogout } from "@/utils/logout";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

export type CloseIconProps = {
  className?: string;
};

export const CloseIcon: React.FC<CloseIconProps> = ({ className }) => {
  const logout = useLogout("close_icon");
  return (
    <XMarkIcon
      className={clsx("w-6 h-6 cursor-pointer", className)}
      onClick={logout}
    />
  );
};
