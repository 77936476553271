import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginToken, Configuration } from "./apiTypes";
import { createContext, useContext } from "react";

let csrfToken: string | null = null;
let flowSessionKey: string | null = null;

export const prepareHeaders = (headers: any) => {
  if (csrfToken) {
    headers.set("X-CSRFToken", csrfToken);
  }

  if (flowSessionKey) {
    headers.set("Flow-Session-Key", flowSessionKey);
  }

  return headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1",
  prepareHeaders,
});

export function createBaseQuery(baseQuery: any) {
  return async (args: any, api: any, extraOptions: any) => {
    // Use base query and capture the response
    const result = await baseQuery(args, api, extraOptions);

    // Intercept the session token from any response
    if (result?.meta?.response?.headers) {
      const newSessionToken =
        result.meta.response.headers.get("Flow-Session-Key");
      if (newSessionToken) {
        flowSessionKey = newSessionToken;
      }
      const newCsrfToken = result.meta.response.headers.get("X-CSRFToken");
      if (newCsrfToken) {
        csrfToken = newCsrfToken;
      }
    }

    return result;
  };
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: createBaseQuery(baseQuery),
  tagTypes: ["Configuration"],
  endpoints: (builder) => ({
    getConfiguration: builder.query<Configuration, void>({
      query: () => ({
        url: "/configuration",
        method: "GET",
      }),
      providesTags: ["Configuration"],
    }),

    login: builder.mutation<void, LoginToken>({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Configuration"],
    }),
    logout: builder.mutation<void, void>({
      query: (body) => ({
        url: "/logout",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Configuration"],
    }),
  }),
});

export const { useGetConfigurationQuery } = api;

export const APIContext = createContext(api);
export function useAPI() {
  return useContext(APIContext);
}
