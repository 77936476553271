import { useAPI } from "@/services/api";
import { useEffect } from "react";
import { useSearchParams } from "@Light/utils/context";
import { useScaffold } from "@Light/scaffold";

export type LoginProps = {};

export const Login: React.FC<LoginProps> = ({}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const { useLoginMutation } = useAPI();
  const [login, loginState] = useLoginMutation();
  const scaffold = useScaffold();

  useEffect(() => {
    if (!token) {
      return;
    }

    login({ token });
  }, [login, token]);

  const Navigate = scaffold.system.navigate;
  if (!token || loginState.isError || loginState.isSuccess) {
    return <Navigate to="/" />;
  }

  return null;
};
