import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export type EnrollmentNotCompleteProps = {};

export const EnrollmentNotComplete: React.FC<
  EnrollmentNotCompleteProps
> = ({}) => {
  const scaffold = useScaffold();
  const WithHeader = scaffold.page.header.withHeader;
  const PageBody = scaffold.page.pageBody;
  return (
    <WithHeader>
      <PageBody>
        <div className="pt-8">
          <div
            className={clsx(
              "border rounded-lg p-4",
              scaffold.page.colors.border.warn,
              scaffold.page.colors.background.warn,
            )}
          >
            <div className="flex flex-col gap-2">
              <div
                className={clsx(
                  "flex flex-row items-center gap-4",
                  scaffold.page.colors.text.warn,
                )}
              >
                <ExclamationTriangleIcon className="h-8" />
                <div className="font-medium text-lg">Error</div>
              </div>
              <div
                className={clsx("text-sm", scaffold.page.colors.text.primary)}
              >
                You must complete enrollment before you can access this page.
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </WithHeader>
  );
};
