import { useLogout } from "@/utils/logout";
import { EmptyHeader } from "./EmptyHeader";
import { useScaffold } from "@Light/scaffold";

export type ServiceAlreadyActiveProps = {
  title?: string;
};

export const ServiceAlreadyActive: React.FC<ServiceAlreadyActiveProps> = ({
  title,
}) => {
  const logout = useLogout("service_already_active");
  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;

  const companyName = scaffold.page.companyName;

  return (
    <div className="flex flex-col h-full">
      <EmptyHeader />
      <div className="flex justify-center items-center text-center h-full px-4">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <Title>{title || "Your service is active"}</Title>
            <Subtitle>
              You can manage your service within the {companyName} platform.
            </Subtitle>
          </div>
          <button
            className="bg-slate-900 text-white rounded rounded-md p-2"
            onClick={logout}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
