import { createLightApi } from "@Light/services/light";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { createBaseQuery, prepareHeaders } from "./api";

export const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1/account",
  prepareHeaders,
});

export const light = createLightApi(createBaseQuery(baseQuery));
